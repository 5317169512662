

.container {
    position: relative;
    /* margin-top: 80px; */
    /* overflow: hidden; */
}

.parallax {
    background-image: url('https://images.squarespace-cdn.com/content/v1/52e48e04e4b01b78d94d7fab/1646751715704-U32HGMWTT3GAFHJ88E99/angello-lopez-1437933-unsplash.jpg?format=2500w');
    background-attachment: fixed; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    position: relative;
}

.parallax_content {
    position: relative; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    padding: 20px;
    color: white;
    font-size:x-large;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
}

.intro_block {
    /* margin-top: 50px;  */
    color: Black;
    /* max-width: 900px; */
    /* padding-left: 20%;    */
    display: flex;
    flex-direction: row;
    font-family: "Open Sans", sans-serif;
    /* -webkit-font-smoothing: antialiased; */
    text-align: left;
}



.special {
    background-color: rgb(201, 27, 27);
    padding:20px;
    color:white;
    font-weight: 700;
    /* margin-top: 50px; */
}